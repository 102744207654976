// BREADCRUMBS
//
// Example:
// <nav class="breadcrumbs row">
//   <div class="container">
//     <p><a href="/">Home</a> / <a href="">Parent page</a> / <span class="active">Current page</span></p>
//   </div>
// </nav>

.breadcrumbs {
  padding-top: rem($line/2);
  margin-bottom: rem($line);
}