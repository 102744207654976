// Hide for both screenreaders and browsers: http://css-discuss.incutio.com/wiki/Screenreader_Visibility
.ir {
  background-repeat: no-repeat;
  direction: ltr;
  display: block;
  text-align: left;
  text-indent: -999em;
  overflow: hidden;

  br {
    display: none;
  }
}
.hide  {
  display: none;
}
.hidden {
  display: none;
  visibility: hidden;
}

// Hide only visually, but have it available for screenreaders: by Jon Neal. http://www.webaim.org/techniques/css/invisiblecontent/  &  j.mp/visuallyhidden
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638
  &.focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

// Hide visually and from screenreaders, but maintain layout
.invisible {
  visibility: hidden;
}

.sm-show, .md-show, .lg-show {
  display: none !important
}

@media #{$small} { 
  .sm-show { display: block !important }
}

@media #{$medium} { 
  .md-show { display: block !important }
}

@media #{$large} { 
  .lg-show { display: block !important }
}

@media #{$small} {
  .sm-hide { display: none !important }
}

@media #{$medium} {
  .md-hide { display: none !important }
}

@media #{$large} {
  .lg-hide { display: none !important }
}