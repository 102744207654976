h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    text-decoration: none;

    &:link,
    &:visited {

    }
    &:hover,
    &:active,
    &:focus {

    }
  }
}

.hgroup {
  *:first-child {

  }
  > * {
    margin:0;
  }
}