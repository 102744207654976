//BUTTONS

// A collection of button styles for buttons elements, button inputs, and button links.
//
// Start with a .button class and add modifiers classes to er, modify them.
//
// Example:
// <a href="#" class="button button--large">Large Button text</a>



// Large buttons
a.button--large,
.button--large {
  font-size:1.5em;
}

// Small buttons
a.button--small,
.button--small {
  font-size:0.75em;
}

//Button groups
.button-group {
  @extend %group;

  // Organize button groups for mobile screens first (we like to stack 'em).
  .button,
  a.button,
  button {
    display:block;
    margin:0;
    border-radius: 0;
  }

  .button:first-child,
  a.button:first-child,
  button:first-child {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }

  .button:last-child,
  a.button:last-child,
  button:last-child {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }

  // Reorganize for wider canvases (like a fancy pill-style nav).
  @media only screen and (min-width: $screenSmall) {
    .button,
    a.button,
    button {
      float:left;
    }

    .button:first-child,
    a.button:first-child,
    button:first-child {
      border-top-right-radius: 0;
      border-top-left-radius: $radius;
      border-bottom-left-radius: $radius;
    }

    .button:last-child,
    a.button:last-child,
    button:last-child {
      border-bottom-right-radius: $radius;
      border-bottom-left-radius: 0;
      border-top-right-radius: $radius;
    }
  }
}
