// OFFCANVAS NAVIGATION/CONTENT
//
// Example:
//
// <!-- Add 'offcanvas--push' <body> to push the page content when open.
//      Add 'offcanvas--squish' to squish the page content when open. -->
// <body class="offcanvas--push">
//
//   <!-- Left example. Other options are offcanvas--top, offcanvas--right, offcanvas--bottom -->
//   <nav class="offcanvas--left" id="offcanvas">
//     <!-- Offcanvas content. -->
//   </nav>
//
//   <!-- Other site content below -->
//   <main role="main">
//     <!-- Point the href to the id of the offcanvas area -->
//     <div class="js-open-canvas"><a href="#offcanvas">Offcanvas toggle</a></div>
//   </main>
// </body>

// Set the width of vertical offcanvas areas
$offcanvas-width: rem($line*10);
$offcanvas-out: all 500ms cubic-bezier(0.745, -0.190, 0.210, 1.310);
// Set the height of horizontal offcanvas areas
$offcanvas-height: rem($line*4);


.offcanvas--top,
.offcanvas--left,
.offcanvas--bottom,
.offcanvas--right {
  position: fixed;
  z-index: 1000;

  a {
    padding: rem($line);
    display: block;
    text-decoration: none;
    font-family: $sans;
  }

  ul {
    border-top:$blackline;

    a {
      padding: rem($line/2 $line);
    }
  }
}

// Transitions
.offcanvas--top,
.offcanvas--left,
.offcanvas--bottom,
.offcanvas--right,
.offcanvas--push {
  transition: $offcanvas-out;
}

// Vertical offcanvas 
.offcanvas--right,
.offcanvas--left {
  overflow:auto;
  width: $offcanvas-width;
  height: 100%;

  li {
    display: block;
  }
}

// Horizontal offcanvas
.offcanvas--top,
.offcanvas--bottom {
  width: 100%;
  height: $offcanvas-height;
  left: 0;
  overflow: hidden;

  a {
    float: left;
  }
}

// Vertical menu that slides from the left or right: ;
.offcanvas--left {
  left: 0px;
  border-right:$blackline;

  &.offcanvas--open {
    left: $offcanvas-width * -1;
  }
  a {

    &:link,
    &:visited {
      background:transparent;
    }
    &:hover,
    &:active,
    &:focus {
      background:$accent;
      text-shadow:none;
      color:$background;
    }
  }
}

.offcanvas--right {
  right: $offcanvas-width * -1;

  &.offcanvas--open {
    right: 0px;
  }
}

// Horizontal menu that slides from the top or bottom
.offcanvas--top {
  top: $offcanvas-height * -1;

  &.offcanvas--open {
    top: 0px;
  }
}
.offcanvas--bottom {
  bottom: $offcanvas-height * -1;

  &.offcanvas--open {
    bottom: 0px;
  }
}

// Add this class to the body element to push it to the side when the menu opens
.offcanvas--push {
  overflow-x: hidden;
  left: $offcanvas-width;


  &.offcanvas--push-right {
    left: $offcanvas-width * -1;
  }

  &.offcanvas--push-left {
    left: 0;
  }
}

// Squish the canvas instead of pushing it.
.offcanvas--squish {
  overflow-x: hidden;
  left: $offcanvas-width;
  transition: $offcanvas-out;

  &.offcanvas--squish-right {
    left: $offcanvas-width * -1;
  }

  &.offcanvas--squish-left {
    left:0;
  }

  @media only screen and (min-width: $screenSmall) {
    &.offcanvas--squish-right {
      margin-right: $offcanvas-width;
      left:auto;
    }
    &.offcanvas--squish-left {
      margin-left: $offcanvas-width;
      left: auto;
    }
  }
}

.trigger--guide {
  position:fixed;
  top:rem($line/2);
  left:$offcanvas-width - rem(5);
  background:$text;
  z-index:1000;
  padding:rem($line/4 $line/2 $line/4 $line/1.5);
  text-align:center;
  transition: $offcanvas-out;
  border-bottom-right-radius:$radius;
  border-top-right-radius:$radius;
  cursor:pointer;

  a {
    background:none;
  }

  strong {
    color:$background;
  }

  &.active {
    left:rem(-5);

    img {
      transform:rotate(180deg);
      animation: spin-left 300ms ease-out;
    }
  }


  img {
    display:block;
    width:rem($line);
    height:rem($line);
    margin:0 auto;
    transform:rotate(0deg);
    margin-bottom:rem($line/4);
    animation: spin-right 300ms ease-out;
  }
}

@media only screen and (min-width: $screenSmall) {
  .offcanvas--left {
    left: $offcanvas-width * -1;


    &.offcanvas--open {
      left: 0px;
    }
  }
  .offcanvas--squish {
    left:0;
    margin-left:0;

    &.offcanvas--squish-left {
      margin-left:$offcanvas-width;
    }

  }
  .trigger--guide {
    left: rem(-5);

    strong {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    &.active {
      left:$offcanvas-width - rem(5);
      opacity:0.2;

      &:hover {
        opacity:1;
      }
    }
  }
}
