//Halfs
.c2,
.c4x2,
.c6x3 {

}
//Thirds
.c3,
.c6x2 {

}
.c3x2,
.c6x4 {

}
//Quarters
.c4 {

}
.c4x3 {

}
//Fifths
.c5 {

}
.c5x2 {

}
.c5x3 {

}
.c5x4 {

}
//Sixths
.c6 {

}
.c6x5 {

}
.full {
  width:100%;
}
@media #{$small} {
  .c2,
  .c4x2,
  .c6x3,
  .c3,
  .c6x2,
  .c3x2,
  .c6x4,
  .c4,
  .c4x3,
  .c5,
  .c5x2,
  .c5x3,
  .c5x4,
  .c6,
  .c6x5 {
    float:left;
  }

  //Halfs
  .c2,
  .c4x2,
  .c6x3 {
    width: $c2;
  }
  //Push left
  .pushl2,
  .pushl4x2,
  .pushl6x3 {
    margin-left:$c2;
  }
  //Push right
  .pushr2,
  .pushr4x2,
  .pushr6x3 {
    margin-right:$c2;
  }
  //Thirds
  .c3,
  .c6x2 {
    width: $c3;
  }
  //Push left
  .pushl3,
  .pushl6x2 {
    margin-left: $c3;
  }
  //Push right
  .pushr3,
  .pushr6x2 {
    margin-right: $c3;
  }
  .c3x2,
  .c6x4 {
    width:$c3*2;
  }
  //Push left
  .pushl3x2,
  .pushl6x4 {
    margin-left:$c3*2;
  }
  //Push right
  .pushr3x2,
  .pushr6x4 {
    margin-right:$c3*2;
  }
  //Quarters
  .c4 {
    width:$c4;
  }
  //Push left
  .pushl4 {
    margin-left:$c4;
  }
  //Push right
  .pushr4 {
    margin-right:$c4;
  }
  .c4x3 {
    width:$c4*3;
  }
  //Push left
  .pushl4x3 {
    margin-left:$c4*3;
  }
  //Push right
  .pushr4x3 {
    margin-right:$c4*3;
  }
  //Fifths
  .c5 {
    width:$c5;
  }
  //Push left
  .pushl5 {
    margin-left:$c5;
  }
  //Push right
  .pushr5 {
    margin-right:$c5;
  }
  .c5x2 {
    width:$c5*2;
  }
  //Push left
  .pushl5x2 {
    margin-left:$c5*2;
  }
  //Push right
  .pushr5x2 {
    margin-right:$c5*2;
  }
  .c5x3 {
    width:$c5*3;
  }
  //Push left
  .pushl5x3 {
    margin-left:$c5*3;
  }
  //Push right
  .pushr5x3 {
    margin-right:$c5*3;
  }
  .c5x4 {
    width:$c5*4;
  }
  //Push left
  .pushl5x4 {
    margin-left:$c5*4;
  }
  //Push right
  .pushr5x4 {
    margin-right:$c5*4;
  }
  //Sixths
  .c6 {
    width:$c6;
  }
  //Push left
  .pushl6 {
    margin-left:$c6;
  }
  //Push right
  .pushr6 {
    margin-right:$c6;
  }
  .c6x5 {
    width:$c6*5;
  }
  //Push left
  .pushl6x5 {
    margin-left:$c6*5;
  }
  //Push right
  .pushr6x5 {
    margin-right:$c6*5;
  }
}
