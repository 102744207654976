table{
  border-collapse: collapse;
  width: 100%;

  ul,
  ol,
  dl,
  p {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
  }
}
th,
td {
  text-align: left;
  vertical-align:top;
}



// Cell alignments

[colspan]{
  text-align: center;
}
[colspan="1"]{
  text-align: left;
}
[rowspan]{
  vertical-align: middle;
}
[rowspan="1"]{
  vertical-align: top;
}