// IMAGES
//
// Let's make our images responsive. We wrap our images, maps, and videos in <figure> tags.
// It makes semantic sense, and gives us a flexible container.
//
// Example:
// <figure>
//      <img src="cool-thing.jpg" alt="Cool thing" />
//      <figcaption>Cool thing caption.
//          <footer class="source">Photograph by <a href="#">Rad Person</a> at <cite><a href="#">NY Times</a></cite></footer>
//      </figcaption>
// </figure>

img {
  max-width: 100%;
  height: auto;
}
picture,
figure {
  position:relative;

  > svg,
  > img,
  > iframe {
    display: block;
  }
}
figcaption {

}

//Fix SVG proportions in IE
img[src*=svg],
svg {
  max-height: 100%;
  max-width:100%;
  width:100%;
}
img[src*=svg] { width/*\**/: 100%\9;}

@media screen and (min-width:0\0) {
  img[src*=".svg"] {
    width: 100% !important;
  }
  svg,
  svg path {
    width:100% !important;
  }
}