// VCARDS

.vcard {
  .type {
      display:block;
  }
}
.street-address,
.country-name {
    display:block;
}
