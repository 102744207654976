// MAPS
//
// Let's use the same syntax as images, but add a .map class to apply the
// following responsive styles.
//
// Example:
// <figure class="map">
//      <iframe Map embed code></iframe>
// </figure>

.map {
  position:relative;
  max-height:$line*6; // TIP: Keep your map content short on mobile so it doesn't hijack the scroll
  padding-bottom: 56.25%;
  height: 0;
  overflow:hidden;

  object,
  embed,
  iframe {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
  }
}
// Make maps fluid
#googlemap img,
object,
embed,
#map_canvas embed,
#map_canvas img,
#map_canvas object {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (min-width: $screenSmall) {
  .map {
    max-height:auto;
  }
}