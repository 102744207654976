// KEYLINES, RULES, & DIVIDERS
.rule--hairline {
  border-top:$hairline;
}
.rule--light {
  border-top:$lightline;
}
.rule--accent {
  border-top:$accentline;
}
.rule--white {
  border-top:$whiteline;
}
.rule--black {
  border-top:$blackline;
}
