// LINKS

a {

  &:link,
  &:visited {
    color:$blue;
  }
  &:hover,
  &:active,
  &:focus {
    color:$red;
  }
}


sup,
sub {
  a {
    text-decoration: none;
  }
}
