//* =============================================================================
//   Default Colours
//   ========================================================================== */

// Primary colours
$red: #cb2c30;
$blue: #4cb4e7;

// Shades
$white: white;
$off-white: #dad9d6;
$light-grey: #adaeb0;
$grey: #686869;
$dark-grey:#4c4845;
$black: #222223;

// Colour variables - Useful for changing the entire colour scheme
$background: $white;
$dark-background: $off-white;
$text: $black;
$mid-text: $grey;
$light-text: $light-grey;
$accent: $red;