.text-input,
input[type=text],
input[type=password],
input[type=email],
input[type=search],
input[type=number],
input[type=date],
input[type=time],
input[type=file],
textarea {
  @include placeholder-color($light-text);
  -webkit-appearance: none;
  display:block;
  width:100%;
  outline:none;
  border: $rule;
  border-radius: $radius;
  font-family:$sans;
  padding: rem($line/2);
  color: $text;

  &:active,
  &:focus {

  }

  &.error {

  }
}

textarea {
  min-height: rem($line*6);
}


input[type=checkbox],
input[type=radio] {
  margin-right: 0.5em;
  color: $text;
}

select {
  color: $text;
}

input[type=search],
.search-input {
  overflow:hidden;
  box-sizing:border-box;
  color: $text;

  &:focus,
  &:active {

  }
}
