// LAYOUT
//
// Setup your common layout blocks here. We've included a few examples, but
// encourage you to create your own.
//
// Example:
// <section class="row">
//    <section class="primary-content">
//        … Main page stuff …
//    </section>
//    <aside class="secondary-content">
//        … Secondary content (sidebar perhaps?) …
//     </aside>
// </section>


@media only screen and (min-width: $screenSmall) {
  .primary-content {
    width:$c5*3; // Creates an object that's 3/5 the width of its parent
    float:left;  // Float this block to the left

  }
  .secondary-content {
    width:$c5*2; // Creats an object that's 2/5 the width of its parent
    float:right;
  }
  .split-content {
    width:$c4*2; // Creates objects that are 1/2 the width of its parent
    float:left;
  }
  .three-up {
    width:$c3*1; // Creates objects that are 1/3 the width of its parent
    float:left;
  }
}

@media only screen and (min-width: $screenMedium) {

}

@media only screen and (min-width: $screenLarge) {
  .primary-content {
    width:$c7*4; // Change grid as you move up: Four units of a 7-column grid
  }
  .secondary-content {
    width: $c3*1; // Uses a 1/3 unit-grid for a more complex compound grid https://gridsetapp.com/specs/gerstner/
  }
}
@media only screen and (min-width: $screenXlarge) {

}
