// VARIABLES
//
// Set most of your project settings here. Color variables can be found in _colors.scss


///* =============================================================================
//   Base sizing
//   ========================================================================== */
$base: 16; // Set the html base font size (in px). We recommend keep this at 16.
$line: 24; // Set the baseline grid (in px) Also determines gutter width.


///* =============================================================================
//   Borders
//   ========================================================================== */

$rule: 1px solid $light-text;
$hairline: 1px solid $dark-background;
$accentline: 1px solid $accent;
$lightline: 1px solid $background;
$whiteline: 1px solid white;
$blackline: 1px solid black;

// Default border radius
$radius: 3px;


///* =============================================================================
//   Font Stacks
//   ========================================================================== */

$serif: Georgia, serif;
$sans: system, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Lucida Grande", sans-serif;
$mono: monospace;


///* =============================================================================
//   Paragraph defaults
//   ========================================================================== */

// Set to true to indicate parahgraph breaks with indents instead of line-breaks
$indented-paragraphs: false;

// FONT WEIGHTS
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$heavy: 900;


///* =============================================================================
//   Breakpoints
//   ========================================================================== */

// Adjust according to content not device.

$screenXSmall: 28em !default;
$screenSmall: 48em !default;
$screenSmallMedium:54em !default;
$screenMedium: 64em !default;
$screenLarge: 98em !default;
$screenXlarge: 110em !default;

$tiny: "only screen and (min-width : #{$screenXSmall})";
$small: "only screen and (min-width : #{$screenSmall})";
$smallMedium: "only screen and (min-width : #{$screenSmallMedium})";
$medium: "only screen and (min-width : #{$screenMedium})";
$large: "only screen and (min-width : #{$screenLarge})";
$xlarge: "only screen and (min-width : #{$screenXlarge})";

// EG:
// @media #{$small} {
//   background: red;
// }

///* =============================================================================
//   Grid
//   ========================================================================== */

// USAGE
// width: $c6*3; // This is 3 units of a 6 unit grid wide
// margin-left: $c4*2; // This pushes the element 2 units (of a 4 element grid) to the left
//
// Have fun. Make as many as you like. Simple.


// Primary Grid
//=============\\
//Two Column Grid
$c2: 100% / 2;
//Three Column Grid
$c3: 100% / 3;
//Four Column Grid
$c4: 100% / 4;
// Five Columns
$c5: 100% / 5;
//Six Column Grid
$c6: 100% / 6;
//Seven Column Grid
$c7: 100% / 7;
//Eight Column Grid
$c8: 100% / 8;
//Nine Column Grid
$c9: 100% / 9;
//Ten Column Grid
$c10: 100% / 10;
//Twelve Column Grid
$c12: 100% / 12;
//Sixteen Column Grid
$c16: 100% / 16;

// Set the page margins
// We like to make this equivalent to a column size. In this case, we're making
// the margins the same width as unit in a 16 column grid (100/18 = 1 + 16 + 1).
$margin: 100% / 18;


///* =============================================================================
//   Brand colors
//   ========================================================================== */
// We've included some branding colors for easy reference.

$adn: #4A484C;
$android: #80BD01;
$apple: #777777;
$bitbucket: #205081;
$bitcoin: #F7931A;
$css3: #1572B7;
$dribbble: #EA4C89;
$dropbox: #1081DE;
$facebook: #3B579D;
$flickr: #FF1981;
$foursquare: #38AAE1;
$github: #333333;
$gittip: #663300;
$googleplus: #DD4B39;
$html5: #E54D26;
$instagram: #427096;
$linkedin: #1B86BC;
$linux: #000000;
$maxcdn: #FE8424;
$pagelines: #2CA6FE;
$pinterest: #CB2027;
$renren: #005CAC;
$skype: #00AFF0;
$stackexchange: #195398;
$stackoverflow: #FE7A15;
$trello: #2A79A6;
$tumblr: #304E6A;
$twitter: #00ACED;
$vimeo: #20B9EB;
$vk: #54769A;
$weibo: #EB182C;
$windows: #6DC2E9;
$xing: #006464;
$youtube: #CC181E;
