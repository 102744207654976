//  Bordered tables
.table--bordered{

  th,
  td{
    border:$rule;

    &:empty{
      border:none;
    }
  }
}
