// FUNCTIONS

// This strips the unit of measure and returns it
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}


// A handy Rem converting function modified from Foundation 5 http://foundation.zurb.com is the basis of all our units.
// Call with 1, 2, 3 or 4 parameters, 'px' is not required but supported
// rem(10 20 30px 40);
// Space delimited, if you want to delimit using comma's, wrap it in another pair of brackets
// rem((10, 20, 30, 40px));
// Optionally call with a different base (eg: 8px) to calculate rem.
// rem(16px 32px 48px, 8px);
// If you require to comma separate your list
// rem((16px, 32px, 48), 8px);

@function convert-to-rem($value, $base-value: 16)  {
  $value: strip-unit($value) / strip-unit($base-value) * 1rem;
  @if ($value == 0rem) { $value: 0; } // Turn 0rem into 0
  @return $value;
}

@function rem($values, $base-value: $base) {
  $max: length($values);

  @if $max == 1 { @return convert-to-rem(nth($values, 1), $base-value); }

  $remValues: ();
  @for $i from 1 through $max {
    $remValues: append($remValues, convert-to-rem(nth($values, $i), $base-value));
  }
  @return $remValues;
}


// Color palette
// Usage:
//
// background: c(blue, 80); // #1D3649
// background: c(blue);     // #4178BE


@function c($palette, $tone: 'core') {
  @return map-get(map-get($palettes, $palette), $tone);
}