// Floating
.right{
  float:right;
}
.left{
  float:left;
}
.clear {
  clear:both;
}

//Display
.inline {
  display: inline;
}
.inline-block {
  display:inline-block;
}
.block {
  display: block;
}
.table {
  display:table;
}
.table-cell {
  display: table-cell;
}

//Overflow
.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-auto   {
  overflow: auto;
}

//Vertical alignment
.align-baseline { vertical-align: baseline }
.align-top      { vertical-align: top }
.align-middle   { vertical-align: middle }
.align-bottom   { vertical-align: bottom }

//Spacing

.m0  { margin:        0 }
.mt0 { margin-top:    0 }
.mr0 { margin-right:  0 }
.mb0 { margin-bottom: 0 }
.ml0 { margin-left:   0 }
.p0  { padding:       0 }
.pt0 { padding-top:   0 }
.pr0 { padding-right: 0 }
.pb0 { padding-bottom:0 }
.pl0 { padding-left:  0 }

// Eg) .m1 { margin: rem($line) }
//     .pt2 { padding-top: rem($line) }

@for $i from 1 through 10 {
  .m#{$i}  { margin:         #{rem($line) * $i}; }
  .mt#{$i} { margin-top:     #{rem($line) * $i}; }
  .mr#{$i} { margin-right:   #{rem($line) * $i}; }
  .mb#{$i} { margin-bottom:  #{rem($line) * $i}; }
  .ml#{$i} { margin-left:    #{rem($line) * $i}; }
  .my#{$i} { margin-top:     #{rem($line) * $i}; margin-bottom: #{rem($line) * $i}; }
  .mx#{$i} { margin-left:    #{rem($line) * $i}; margin-right:  #{rem($line) * $i}; }
  .p#{$i}  { padding:        #{rem($line) * $i}; }
  .pt#{$i} { padding-top:    #{rem($line) * $i}; }
  .pr#{$i} { padding-right:  #{rem($line) * $i}; }
  .pb#{$i} { padding-bottom: #{rem($line) * $i}; }
  .pl#{$i} { padding-left:   #{rem($line) * $i}; }
  .py#{$i} { padding-top:    #{rem($line) * $i}; padding-bottom: #{rem($line) * $i}; }
  .px#{$i} { padding-left:   #{rem($line) * $i}; padding-right:  #{rem($line) * $i}; }
  .z#{$i}  { z-index: #{$i} }
}

//Positioning
.relative { position: relative }
.absolute { position: absolute }
.fixed    { position: fixed }

.top-0    { top: 0 }
.right-0  { right: 0 }
.bottom-0 { bottom: 0 }
.left-0   { left: 0 }

//Flex
.flex { display: flex }

.flex-column  { flex-direction: column }
.flex-wrap    { flex-wrap: wrap }

.flex-center   { align-items: center }
.flex-baseline { align-items: baseline }
.flex-stretch  { align-items: stretch }
.flex-start    { align-items: flex-start }
.flex-end      { align-items: flex-end }

.flex-justify  { justify-content: space-between }

.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}
.flex-grow { flex: 1 0 auto }
.flex-none { flex: none }

.flex-first { order: -1 }
.flex-last  { order: 99999 }

@media #{$small} {
  .sm-flex { display: flex }
}
@media #{$medium} {
  .md-flex { display: flex }
}
@media #{$large} {
  .lg-flex { display: flex }
}


//VH units
.vh100 {
  min-height:100vh;
}
.vh75 {
  min-height:75vh;
}
.vh66 {
  min-height:66vh;
}
.vh50 {
  min-height:50vh;
}
.vh33 {
  min-height:33vh;
}
.vh25 {
  min-height:25vh;
}

/* fix iOS bug not displaying 100vh correctly */
/* ipad */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.vh100 {
		min-height: 768px;
	}
  .vh75 {
    min-height:576px;
  }
  .vh66 {
    min-height:507px;
  }
  .vh50 {
    min-height:384px;
  }
  .vh33 {
    min-height:253px;
  }
  .vh25 {
    min-height:192px;
  }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
  .vh100 {
		min-height: 1024px;
	}
  .vh75 {
    min-height:768px;
  }
  .vh66 {
    min-height:676px;
  }
  .vh50 {
    min-height:512px;
  }
  .vh33 {
    min-height:338px;
  }
  .vh25 {
    min-height:256px;
  }
}
/* iphone5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
  .vh100 {
		min-height: 320px;
	}
  .vh75 {
    min-height:240px;
  }
  .vh66 {
    min-height:212px;
  }
  .vh50 {
    min-height:160px;
  }
  .vh33 {
    min-height:106px;
  }
  .vh25 {
    min-height:80px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  .vh100 {
		min-height: 568px;
	}
  .vh75 {
    min-height:426px;
  }
  .vh66 {
    min-height:375px;
  }
  .vh50 {
    min-height:284px;
  }
  .vh33 {
    min-height:193px;
  }
  .vh25 {
    min-height:142px;
  }
}
/* iPhone 4 */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
  .vh100 {
		min-height: 320px;
	}
  .vh75 {
    min-height:240px;
  }
  .vh66 {
    min-height:212px;
  }
  .vh50 {
    min-height:160px;
  }
  .vh33 {
    min-height:106px;
  }
  .vh25 {
    min-height:80px;
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
  .vh100 {
		min-height: 480px;
	}
  .vh75 {
    min-height:360px;
  }
  .vh66 {
    min-height:317px;
  }
  .vh50 {
    min-height:240px;
  }
  .vh33 {
    min-height:158px;
  }
  .vh25 {
    min-height:120px;
  }
}
